import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { routes } from './app.routes';
import { jwtInterceptor } from './core/interceptors/jwt.interceptor';
import { errorInterceptor } from './core/interceptors/error.interceptor';
import { provideToastr } from 'ngx-toastr';
import { ToastComponent } from './shared/components/toast/toast.component';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([jwtInterceptor, errorInterceptor])),
    provideAnimationsAsync(),
    provideToastr({ positionClass: 'toast-bottom-right', toastComponent: ToastComponent, timeOut: 4000 }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: (environment.environmentName !== 'DEV'),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};