import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TOAST_TYPE, ToastService } from '../../shared/components/toast/toast.service';
import { PermissionsService } from '../services/permissions.service';
import { ADVANCED_PERMISSIONS, MODULE_PERMISSIONS } from '../models/enum';
import { map, Observable, of, tap } from 'rxjs';

export const permissionGuard: CanActivateFn = (route, state) => {

  const toast = inject(ToastService);
  const permissionsService = inject(PermissionsService);
  const router = inject(Router)
  const routeData: { module: MODULE_PERMISSIONS, permissionLevel: ADVANCED_PERMISSIONS, fallBackUrl: string } = route.data as any;
  let permissionObservable: Observable<boolean>;

  if (routeData.module && routeData.permissionLevel) {
    permissionObservable = permissionsService.hasPermission(routeData.module, routeData.permissionLevel);
  } else {
    permissionObservable = of(false);
  }

  return permissionObservable.pipe(
    tap((hasAccess) => { 
      if (!hasAccess) { 
        router.navigate([routeData.fallBackUrl]);
        toast.showToast('ph-fill ph-warning', 'You do not have permission to access this page.', TOAST_TYPE.ERROR);
      }
    }),
    map((hasAccess) => hasAccess)
  );

};