import { HttpErrorResponse, HttpEvent, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, tap, throwError } from 'rxjs';
import { RESPONSE_CODES } from '../../shared/models/enum';
import { AuthenticationService } from '../services/authentication.service';
import { inject } from '@angular/core';
import { TOAST_TYPE, ToastService } from '../../shared/components/toast/toast.service';
import { Router } from '@angular/router';

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next) => {
    const authenticationService = inject(AuthenticationService);
    const toastService = inject(ToastService);
    const router = inject(Router);

    return next(req).pipe(tap((res: HttpEvent<any>) => {

        if (res instanceof HttpResponse && !res.body.success) {

            switch (res.body.code) {
                
                case RESPONSE_CODES.MISSING_SYSTEM_PERMISSIONS:
                    toastService.showToast('ph-fill ph-warning', "You don't have required permissions, Please contact your System Administrator", TOAST_TYPE.ERROR);
                    router.navigate(['/']);
                    break;
                case RESPONSE_CODES.ERROR:
                    toastService.showToast('ph-fill ph-warning', res.body.message, TOAST_TYPE.ERROR);
                    break;
                case RESPONSE_CODES.UNAUTHORIZED:
                    toastService.showToast('ph-fill ph-warning', 'Unauthorized!', TOAST_TYPE.ERROR);
                    authenticationService.logOutUser();
                    break;
            }
        }
    }),

        catchError((err: any) => {
            if (err instanceof HttpErrorResponse && navigator.onLine) {
                toastService.showToast('ph-fill ph-warning', err.message, TOAST_TYPE.ERROR);
            }
            return throwError(() => err);
        })
    );
}