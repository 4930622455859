import { Routes } from "@angular/router";
import { permissionGuard } from "../../../../core/guards/permissions.guard";
import { MODULE_PERMISSIONS, ADVANCED_PERMISSIONS } from "../../../../core/models/enum";

export const generalRoutes: Routes = [
    {
        path: 'quick-contacts',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.QUICK_CONTACTS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_CONTACTS, fallBackUrl: 'dashboard' },
        loadComponent: () => import('./quick-contacts/quick-contacts.component').then(c => c.QuickContactsComponent)
    }
]