import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { TOAST_TYPE, ToastService } from './shared/components/toast/toast.service';
import { ALERT_DIALOG_TYPE, AlertDialogService } from './shared/components/alert-dialog/alert-dialog.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements AfterViewInit {

  environment = environment;
  isOnline = signal<boolean>(navigator.onLine);

  constructor(
    private _swUpdate: SwUpdate,
    private _toastService: ToastService,
    private _alertDialogService: AlertDialogService
  ) { }

  @HostListener('window:online')
  onOnline() {
    this.isOnline.set(true);
    this.reloadApp();
  }

  @HostListener('window:offline')
  onOffline() {
    this.isOnline.set(false);
  }

  reloadApp() {
    if (navigator.onLine) {
      location.reload();
    }
  }

  ngAfterViewInit(): void {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.versionUpdates.subscribe((event) => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            this._toastService.showToast('ph-fill ph-cloud-arrow-down', 'Downloading New Update', TOAST_TYPE.SUCCESS);
            break;
          case 'VERSION_READY':
            this._alertDialogService.showAlertDialog({
              dialogType: ALERT_DIALOG_TYPE.ACKNOWLEDGE,
              dialogIcon: 'ph ph-cloud-arrow-down',
              dialogTitle: { leading: { text: 'Update Available' } },
              dialogMessage: 'A new version of the Manage Web is available',
              primaryButtonProps: { buttonText: 'Update', leadingIcon: 'ph-bold ph-download-simple' },
              onPrimaryButtonPressed: () => {
                this.reloadApp();
              }
            })
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.error('Unrecoverable state reached, reloading page');
            this.reloadApp();
            break;
        }
      });
    }
  }

}