import { Routes } from "@angular/router";
import { MODULE_PERMISSIONS, ADVANCED_PERMISSIONS } from "../../../../core/models/enum";
import { permissionGuard } from "../../../../core/guards/permissions.guard";

export const staffManagementRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'departments-management',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.ROLES_AND_DEPARTMENTS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_ROLES_DEPARTMENTS, fallBackUrl: 'dashboard' },
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./departments/departments.component').then(c => c.DepartmentsComponent)
                    },
                    {
                        path: 'roles-management/:id',
                        loadComponent: () => import('./departments/routes/roles/roles.component').then(c => c.RolesComponent)
                    },
                ]
            },
            {
                path: 'staff-directory',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.STAFF_DIRECTORY, permissionLevel: ADVANCED_PERMISSIONS.VIEW_STAFF, fallBackUrl: 'dashboard' },
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./staff-directory/staff-directory.component').then(c => c.StaffDirectoryComponent)
                    },
                    {
                        path: 'edit-staff/:id',
                        canActivate: [permissionGuard],
                        data: { module: MODULE_PERMISSIONS.STAFF_DIRECTORY, permissionLevel: ADVANCED_PERMISSIONS.EDIT_STAFF, fallBackUrl: 'dashboard/staff-management/staff-directory' },
                        loadComponent: () => import('./staff-directory/routes/add-staff/add-staff.component').then(c => c.AddStaffComponent)
                    },
                    {
                        path: 'add-staff',
                        canActivate: [permissionGuard],
                        data: { module: MODULE_PERMISSIONS.STAFF_DIRECTORY, permissionLevel: ADVANCED_PERMISSIONS.ADD_STAFF, fallBackUrl: 'dashboard/staff-management/staff-directory' },
                        loadComponent: () => import('./staff-directory/routes/add-staff/add-staff.component').then(c => c.AddStaffComponent)
                    },
                ]
            },
            {
                path: 'helpers',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.HELPER, permissionLevel: ADVANCED_PERMISSIONS.VIEW_HELPERS, fallBackUrl: 'dashboard' },
                children: [
                    {
                        path: '',
                        loadComponent: ()=> import('./helpers/helpers.component').then(c => c.HelpersDirectoryComponent)
                    },
                    {
                        path: 'edit-helper/:id',
                        canActivate: [permissionGuard],
                        data: { module: MODULE_PERMISSIONS.HELPER, permissionLevel: ADVANCED_PERMISSIONS.EDIT_HELPERS, fallBackUrl: 'dashboard/staff-management/helpers' },
                        loadComponent: ()=>  import('./helpers/routes/add-helper/add-helper.component').then(m => m.AddHelpersComponent)
                    },
                    {
                        path: 'add-helper',
                        canActivate: [permissionGuard],
                        data: { module: MODULE_PERMISSIONS.HELPER, permissionLevel: ADVANCED_PERMISSIONS.ADD_HELPERS, fallBackUrl: 'dashboard/staff-management/helpers' },
                        loadComponent: ()=>  import('./helpers/routes/add-helper/add-helper.component').then(m => m.AddHelpersComponent)
                    }
                ]
            }
        ]
    },
]